/* © Copyright 2021 Micro Focus or one of its affiliates.
The only warranties for products and services of Micro Focus and its affiliates and licensors (“Micro Focus”) are set forth in the express warranty statements accompanying
such products and services. Nothing herein should be construed as constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors
or omissions contained herein. The information contained herein is subject to change without notice.
Contains Confidential Information. Except as specifically indicated otherwise, a valid license is required for possession, use or copying. Consistent with FAR 12.211 and
12.212, Commercial Computer Software, Computer Software Documentation, and Technical Data for Commercial Items are licensed to the U.S. Government under vendor's standard
commercial license.
 */

export const en_US_StringVariables = {
    "username": "Username",
    "password": "Password",
    "signIn": "Sign In",
    "enterValidCredentials": "Enter Valid Credentials",

    "dataStoreTitle": "Data Store",
    "dataStoreDescription": "Configure Data Store for User Preferences and Credentials",

    "auditServerTitle": "Audit Server",
    "auditServerDescription": " Configure Audit Server and Manage Audit Events",
    "invalidIpHost": "Invalid IP or Host name",
    "invalidPort": "Invalid Port",
    "maxlength": "Name can be max 32 characters long",
    "validName": "Enter valid Name",

    "identityStoreTitle": "Identity Store",
    "identityStoreDescription": "Manage Corporate Identities for SecureLogin",
    "setUpIdentityStore": "Configure Identity Store",

    "helpTitle": "Help",
    "helpDescription": " Documentation",

    "auditServerNotConfigured": "The audit server is not configured",
    "select": "Select",
    "toConfigureAndStart": "to configure and start",
    "supportsOnlyCEF": "SecureLogin supports events in the CEF format. ",

    "setUpAuditServer": "Configure Audit Server",
    "auditServerName": "Audit Server Name",
    "required": "Required",
    "ipAddressOrHostName": "IP Address or Hostname",
    "portNumber": "Port Number",
    "format": "Format",
    "CEF": "CEF",
    "save": "Save",
    "update": "Update",
    "cancel": "Cancel",

    "auditServers": "Audit Servers",
    "newAuditServer": "New Audit Server",
    "auditServer": "Audit Server",

    "deleteConfirmation": " Are you sure you want to delete?",
    "delete": "Delete",

    "OK": "OK",
    "success": "Success",
    "signOut": "Sign Out",

    "SuccessfullyAddedTitle": "Configured the Audit Server",
    "SuccessfullyUpdatedTitle": "Updated the Audit Server",
    "SucessfullyDeletedTitle": "Deleted the Audit Server",

    "resourceAlreadyExists": "Resource already exists / Duplication error",
    "identityTitle": "Identity Store Configuration",

    "dataStore" : "Data Store",
    "DataStoreNotConfigured":"Data Store is not configured",
    "setUpDataStore": "Configure Data Store",
    "supportsOnlyPostgressDB":"SecureLogin currently supports only Postgress DB.",
    "configuringDataStore":"You can configure Data Store details in the server/values.yaml file. For information about how to configure these details, see",
   

    "idleSession": "Your session has expired.",
    "reLogin":"Click ok to login again."
}